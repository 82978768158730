import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200mm"
      height="200mm"
      version="1.1"
      viewBox="0 0 200 200"
    >
      <g fontSize="160.132" fontWeight="bold">
        <g
          fill="#37aace"
          strokeWidth="3.666"
          transform="scale(.99007 1.01003)"
          style={{ lineHeight: "1.25" }}
        >
          <path d="M141.259 146.563h-2.346q-28.852 0-44.88-15.95-16.03-15.952-16.03-44.412 0-28.383 15.951-44.49 16.029-16.107 44.099-16.107 28.383 0 44.177 15.95 15.872 15.951 15.872 44.647 0 19.703-8.444 33.934-8.366 14.23-24.16 21.424l23.535 26.271h-28.696zm-3.206-99.144q-13.761 0-21.346 10.164-7.584 10.165-7.584 28.618 0 18.765 7.428 28.773 7.428 9.93 21.502 9.93 13.84 0 21.424-10.164 7.584-10.165 7.584-28.54 0-18.452-7.584-28.617-7.584-10.164-21.424-10.164z"></path>
        </g>
        <g
          fill="#fff"
          strokeWidth="4.341"
          transform="scale(.99018 1.00992)"
          style={{ lineHeight: "1.25" }}
        >
          <path d="M90.126 146.535h-47.07l-7.428 21.268H5.368l43.24-116.737h35.888l43.239 116.737h-30.26zm-39.564-21.658h31.98L66.59 78.432z"></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
